import * as React from "react";
import Typography from "@mui/material/Typography";
import HomeCatalogNavBar from "../../homeCatalogImgWrapper";
import { CatMenuListWrapper, CatMenuWrapper } from "../catalog.style";
import { Link } from "react-router-dom";

export default function CatMenu(props) {
  const { element = {} } = props;
  const { name: Title = "", id = "", children = [] } = element || {};

  return (
    <CatMenuWrapper className="menu-drop-down">
      <div>
        <HomeCatalogNavBar imgsrc={id} />
        <Typography variant="body" color={"GrayText"} sx={{ fontSize: 14 }}>
          {Title}
        </Typography>
      </div>
      <CatMenuListWrapper className="menu-drop-down-list">
        {children?.length > 0 &&
          children?.map((child, idx) => (
            <Link
              to={`/products/${child?.id}`}
              className="menu-list-link"
              key={`${child?.id}${idx}`}
            >
              {child?.name}
            </Link>
          ))}
      </CatMenuListWrapper>
    </CatMenuWrapper>
  );
}
