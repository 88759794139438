import React, { useEffect, useState } from "react";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";

const PaginationSection = (props) => {
  const {
    productListCount = 0,
    pageSize = 0,
    offset = 0,
    updateURI = () => {},
  } = props;
  const [page, setPage] = useState(null);
  const [pageCount, setPageCount] = useState(null);
  const handleChange = (event, value) => {
    updateURI(value);
  };

  useEffect(() => {
    const pagesCount = productListCount / pageSize;
    const currPage = offset || 1;
    setPageCount(Math.ceil(pagesCount));
    setPage(currPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <Stack spacing={2}>
        <Pagination
          count={pageCount}
          page={page}
          variant="outlined"
          color="primary"
          onChange={handleChange}
          sx={{ alignSelf: "center", padding: 4 }}
          size="large"
        />
      </Stack>
    </React.Fragment>
  );
};
export default PaginationSection;
